@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;700&display=swap');

.hexea-container {
  padding: 0;
}
.hexea-container iframe {
  font-family: 'Mulish', sans-serif !important;
  min-height: none;
}
.ids-row {
  padding:0px !important;
  margin: 0px !important;
}
.ids-row .payment-title {
  padding-bottom: 24px !important;
}
.ids-col {
  padding:0px !important;
}
.paymentConfimation p,
h1,
h2,
input {
  font-family: 'Mulish', sans-serif !important;

}

.gray-background {
  background-color: #F4F5F7;
  min-height: 100vh;
}

.payment-error-container.container-fluid {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.hexea-container iframe .input.card {
  font-family: 'Mulish', sans-serif !important;
  min-height: 0 !important;
  font-size: 16px;
}

.hexea-container .title {
  color: #212224;
}

div:empty:before {
  content: attr(data-placeholder);
  color: gray
}

.hexea-container .form-group {}

.hexea-container .form-control {
  height: 50px;
  font-size: 16px !important;
  font-family: muli;
  color: #3d3d3d;
}

.hexea-container .form-control::placeholder {
  font-size: 16px !important;
  font-family: muli;
  color: #979797;
}

.hexea-container .form-control-error {
  border-color: #E31212;
  color: #E31212;
}
.hexea-container .form-control-error::placeholder {
  color: #E31212;
}

.hexea-container .form-control:focus {
  border-color: rgba(0, 0, 0, 0.1);
  box-shadow: none;
}
.hexea-container .form-control-error:focus {
  border-color: #E31212;
  box-shadow: none;
}

.hexea-container iframe {
  height: 26px !important;
}

.hexea-container h6 {
  color: #1B2125;
  font-size: 1.8rem;
  font-family: muli;
  font-weight: bold;
  line-height: 1.6;
}

.input-wrap.mastercard>.icon.icon-mastercard,
.input-wrap.discover>.icon.icon-discover,
.input-wrap.amex>.icon.icon-amex {
  display: none;
}



.paymentConfimation .IDS.navbar {
  background: #312852;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  border-radius: 0;
}

.IDS.navbar h1 {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  color: white;
  /* identical to box height */
  text-align: center;
}

.IDS.body {
  flex: 1;
  margin-top: 16px !important;
  margin-left: 0;
  margin-right: 0;
}

.IDS.body .subtitle {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  /* identical to box height */
  /* Bg / Dark */
  color: #212224;
}

.IDS.body .section.subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 0;
  /* identical to box height */
  /* Text / Black */
  color: #000000;
}

.IDS.body .payment-details {
  // padding-bottom: 2rem !important;
}


.IDS.body .payment-details p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  // line-height: 20px;
  padding: 0;
  /* identical to box height */
  /* Text / Black */
  color: #000000;
}

.IDS.body .payment-details .description .total {
  text-align: right;
  font-weight: bold;
}
.IDS.body .payment-details .description .title {
  font-weight: 600;
  margin-bottom: 12px !important;
}
.IDS.body .payment-details .description .extra {
  font-size: 14px;
  color: #8E9194;
}
.IDS.body .payment-details .description .fees {
  margin-top: 14px !important;
  font-size: 14px;
}


.IDS.body .payment-details .description p {
  margin: 0 !important;
}

.IDS.body .payment-method {
  border-top: 1px solid #DDDDDD;
  margin-top: 24px !important;
}

.IDS.body .payment-method .subtitle {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */
  /* Bg / Dark */
  color: #212224;
}

.IDS.body .payment-method .card {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */
  /* Text / Black */
  color: #000000;
}

.IDS.body .payment-method p {
  margin-top: 8px !important;
}

.IDS.body .submit-controls {
  margin-top: 5px !important;
}

.IDS.body .submit-controls .btn-submit {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-align: center !important;
  /* White/Default */
  background: #312852;
  color: white;
  padding: 1rem !important;
  justify-content: center;
  border-radius: 10px;
}

.IDS.body .submit-controls .btn-cancel {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center !important;
  /* White/Default */
  background: transparent;
  color: #212224;
  padding: 1rem !important;
  margin-bottom: 16px;
  border-radius: 10px;
}

.IDS.body .submit-controls .row {
  margin-top: 10px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paymentConfimation .footer {
  margin-top: 40px !important;
  text-align: left;
  font-size: small;
}

.paymentText {
  text-align: center;
  font-size: small;
}

.paymentConfimation .footer a {
  color: #9B59B6;
}

.payment-success {
  margin-top: 64px;
  margin-bottom: 16px;
}

.paymentConfimation .payment-success .check-container {
  width: 64px;
  height: 64px;
  background: #EAEAED;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-bottom: 24px;
}

.paymentConfimation .payment-error .check-container {
  width: 64px;
  height: 64px;
  background: #FDEBEB;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-bottom: 24px;
}

.paymentConfimation .payment-success .check-container i {
  font-size: 3rem;
  font-weight: normal;
  color: #302A50;
}

.paymentConfimation .payment-error .check-container i {
  font-size: 4rem;
  font-weight: normal;
  color: #F03738;
}

.paymentConfimation .payment-success .details h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 16px !important;
  text-align: center;

  /* Black/Default */
  color: #000000;
}

.paymentConfimation .payment-success .details p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */
  text-align: center;

  /* Black/Default */
  color: #000000;
}

.payment-spinner {
  margin: auto;
}

.grecaptcha-badge {
  display: none !important;
}

.suggestion-alert {
  background: #FDEBEB;
  padding-top: 9px;
  padding-bottom: 9px;
  justify-content: center;
  flex-direction: row;
  margin-top: 64px;
  display: flex;
}

.suggestion-alert-message {
  display: flex;
  flex: 0.9;
  margin-left: 5px;
  margin-right: 20px;
  align-items: center;
}

.suggestion-alert-text {
  color: #F03738;
  margin-left: 5px;
}

.suggestion-alert-icon {
  color: #F03738;
}

.payment-error-description {
  text-align: center;
}

.payment-error-title {
  color: black;
}

.notification-email {
  padding: 0;
  margin-top: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */
  /* Text / Black */
  color: #000000;
  text-align: center;
}

.payment-review-card {
  padding: 0 8px 0 8px;
  margin: 0 8px 0 8px;
}

.payment-review-card-item {
  padding: 16px;
  background-color: #FFFFFF;
  border-radius: 8px;  
  margin: 24px 0 24px 0;
}

.payment-review-navbar {
  background-color: #FFFFFF !important;
  h1 {
    color: #212224 !important;
    font-weight: bold !important;
  }  
}

.stepper-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin-top: 64px;
}

.stepper-item {
  display: flex;
  flex:1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.stepper-item-text {
  display: flex;
  font-size: 14px;
  margin: 12px;
  text-align: center;
  align-items: center;
  color: #8E9194;
}
.stepper-line {
  flex: 1;
  height: 1px;
  background-color: #CCCCCC;
}