@import "colors";

@import url("https://use.typekit.net/ykq5lvq.css");

.error-container {
  display: flex;
  justify-content: space-around;
  background-color: red;
}

.error-container {
  flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-container.right {
  flex-direction: column;
}

h1.error-title {
  font-size: 48px;
  font-weight: bold;
  text-align: center;
  margin-top: 8%;
  font-family: muli;
  color: $congregate-blue;
  margin-bottom: 26px;
}
h1.error-description {
  font-size: 20px;
  color: black;
  font-weight: normal;
  line-height: 25px;
}

.error-topbar {
  height: 120px;
  width: 100%;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 4px 8px rgba(102, 102, 102, 0.25);
}

.error-topbar-title {
  font-family: muli;
  margin-left: 26px;
  font-size: 32px;
  font-weight: 300;
  color: black;
  line-height: 40px;
}
.error-topbar-container {
  height: 120px;
  flex: 1;
  margin: 0 200px 0 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 0;
  flex-direction: row;
}

.error-topbar-left {
  display: flex;
}
.error-topbar-right {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-topbar-right a {
  font-weight: bold;
  text-decoration: none;
  color: $congregate-blue;
}

.error-body {
  max-width: 600px;
  width: 50%;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.error-img-divider {
  margin: auto;
  margin-top: 50px;
  width: 100%;
}

.error-steps-element::marker {
  font-family: muli;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  color: #9b59b6;
}
.error-steps-element p {
  font-family: muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  margin: 20px 0 20px 0;

  /* identical to box height */

  color: #000000;
}
p.error {
  color: red;
  margin-top: 0.5em;
}
.error-steps-container ol {
  margin: 20px 0 20px 0;
}

.badge-link {
  display: flex;
}
.badges-container {
  display: flex;
  align-items: center;
}
.store-badge {
  width: 136px;
}
.store-badge.google-play {
  width: 170px;
  max-height: 65px;
}
.error-steps-container h1 {
  color: black;
  font-size: 24px;
  font-family: muli;
  font-weight: 800;
  text-align: left;
}
@media only screen and (max-width: 600px) {
  .error-topbar-container {
    margin: 0 2% 0 2%;
    flex-direction: column;
  }
  .error-body {
    width: 90%;
  }
  .store-badge {
    width: 100px;
  }
  .store-badge.google-play {
    width: 120px;
    max-height: 65px;
  }
}
