@import "colors";

@import url("https://use.typekit.net/ykq5lvq.css");

body {
  font-family: muli !important;
}

.RSVP-event-list {
  background-color: #fafafa;
}
.RSVP-navbar {
  height: 64px;
  width: 100%;
  margin-bottom: 10px;
  box-shadow: 0px 4px 8px rgba(102, 102, 102, 0.25);
  padding-left: 24px;
  margin-bottom: 120px;
  background-color: #ffffff;
  position: fixed;
  z-index: 10000;
  top: 0;
}

.no-margin {
  margin: 0 !important;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.event-container {
  display: flex;
  flex-direction: column;
  width: 632px;
  justify-content: flex-start;
  align-items: center;
  float: none;
  margin: auto;
  margin-top: 73px;
  min-height: 80vh;
  z-index: 0;
  padding: 0px 16px 0px 16px;
}
.no-padding {
  padding: 0;
}
.topbar-logo {
  width: 40px;
  height: auto;
  object-fit: contain;
}

.topbar-title {
  font-family: muli;
  margin: 0;
  margin-left: 10px;
  font-size: 18px;
  font-weight: bold;
  line-height: 23px;
  letter-spacing: 0.005em;
  color: #222222;
}
.RSVP-topbar-container {
  height: 64px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 0;
  flex-direction: row;
  float: none;
  margin: auto;
}

.RSVP-topbar-left {
  display: flex;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
}
.topbar-right {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.topbar-right a {
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  color: $congregate-blue;
}

h1.RSVP-event-title {
  font-size: 32px;
  font-weight: bold;
  line-height: 40px;
  text-align: center;
  font-family: muli;
  color: #222222;
  margin-bottom: 26px;
  margin-top: 47px;
}

.RSVP-event-column-title {
  font-size: 20px;
  font-weight: 700;
  font-family: muli;
  font-style: normal;
  line-height: 25.1px;
  color: #222222;
}

.RSVP-event-body-img {
  width: 100%;
  max-width: 100%;
  max-height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 15px;
}

.RSVP-event-body-img-new {
  width: 100%;
  max-width: 100%;
  max-height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 15px;
}
.RSVP-event-ended {
  font-family: muli;
  padding: 0px 16px 0px 16px;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;

}
.RSVP-event-body-column {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.RSVP-event-body-schedule p {
  margin: 0;
  font-family: muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

.RSVP-event-body-schedule {
  padding-bottom: 0;
  margin-top: 10px;
}

.RSVP-event-body-seats {
  font-family: muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #9b59b6;
  margin: 10px 0 10px 0 !important;
}
.RSVP-event-subtitle {
  font-family: muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #222222;
}

.RSVP-description {
  font-family: muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin-left: 8px;
  line-height: 23.68px;
  word-break: break-word;
  color: #666666;
}
.RSVP-description-text {
  font-family: muli;
  font-style: normal;
  font-weight: normal;
  line-height: 23.68px;
  word-break: break-word;
  color: #666666;
}
.RSVP-event-body-column-footer {
  padding-left: 0;
  padding-right: 8px !important;
}
.chungus {
  height: 100%;
  width: 100%;
}

.RSVP-event-link {
  display: flex;
  flex-direction: row;
  color: #312852;
  flex: 1;
  align-items: center;
  // white-space: nowrap;
  overflow: hidden;
  font-family: muli !important;
  margin: 0 !important;
}
.RSVP-event-link:focus{
  outline: none;
}
.RSVP-event-link-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  width: 100%;
}

.RSVP-event-link-container p {
  font-size: 14px;
  font-family: muli, sans-serif;
  font-weight: bolder;
  margin-left: auto;
}
.RSVP-event-link-container input:focus {
  border-width: 0px;
}

.RSVP-event-link.link {
  font-family: muli;
  font-style: normal;
  margin-top: 2rem;
  font-weight: bold;
  font-size: 16px;
  text-decoration: none;
}

.RSVP-event-link.link i {
  margin-right: 5px;
}

p.RSVP-event-link-copied {
  color: rgba(0, 0, 0, 0.26);
}

.RSVP-event-link input {
  border: 0;
  flex: 1;
  text-overflow: ellipsis;
  // white-space: nowrap;
  overflow: hidden;
  background: none;
  width: 100%;
  color: black;
  cursor: pointer;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.RSVP-sign-in {
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(102, 102, 102, 0.25);
  border-radius: 8px;
  padding: 10px;
  margin-top: 10px;
}

.RSVP-sign-in a {
  cursor: pointer;
}

.RSVP-sign-in-custom-input-top-label {
  position: absolute;
  margin-top: -6px;
  margin-left: 11px;
  font-size: 12px;
  background-color: white;
  width: auto;
  z-index: 1;
  padding-left: 4px;
  padding-right: 4px;
} 


.RSVP-sign-in .title {
  font-family: muli;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}
.RSVP-sign-up-summary {
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(102, 102, 102, 0.25);
  border-radius: 8px;
  width: 100%;
  padding: 16px;
}
.RSVP-sign-up-summary p {
  margin: 0 0 4px 0;
}
.RSVP-sign-up-summary .title {
  font-family: muli;
  font-style: normal;
  font-weight: 800;
  text-align: left;
  color: black;
  font-size: 24px;
  line-height: 30px;
}
.RSVP-sign-up-summary .val-title {
  font-family: muli;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;

  /* identical to box height */
  display: flex;
  align-items: center;
}

.footer-tip h3 {
  font-family: muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

.footer-download-suggestion h3 {
  font-family: muli;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #666666;

}

.val-title p {
  font-family: muli;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  font-weight: normal;
  margin-left: 5px;
  /* identical to box height */
  display: flex;
  align-items: center;
}

.RSVP-sign-up-summary .subtitle {
  font-family: muli;
  // font-style: normal;
  // font-weight: normal;
  // font-size: 20px;
  // line-height: 25px;
  // display: flex;
  // align-items: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

}

.required {
  color: #9b59b6;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}
.mt-3 {
  margin-top: 3rem;
}
.mt-4 {
  margin-top: 4rem;
}
.mt-5 {
  margin-top: 5rem;
}
.ml-5 {
  margin-left: 5rem;
}
.mb-1 {
  margin-bottom: 1rem;
}
.mb-2 {
  margin-bottom: 2rem;
}

.checkbox-custom {
  display: flex;
  align-items: center;
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}

.checkbox-custom-check {
  width: 18px;
  height: 18px;
  border: 2px solid rgba(0, 0, 0, 0.5);
  border-radius: 0.3rem;
  margin-right: 12px;
  padding:0;
  cursor: pointer;
}
.checkbox-custom-check-selected {
  width: 20px;
  height: 20px;
  border-width: 2px solid;
  border-radius: 0.3rem;
  color: #9b59b6;
  margin-right: 12px;
  cursor: pointer;
}

.checkbox-custom-check-selected-icon {
  margin-left: -3px;
  margin-top: -3px;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.signInModal {
  margin: auto;
  height: 320px;
  position: absolute;
  background-color: white;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  inset: 40px;
  overflow: auto;
  outline: currentcolor none medium;
  padding: 1.8rem;
}
.right-content {
  display: flex;
  justify-content: flex-end;
}
.signInModal h1 {
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  /* Text / Black */
  color: #000000;
  /* Inside Auto Layout */
  flex: none;
  order: 2;
  align-self: center;
  margin: 0px 8px;
}
.signInModal h3 {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  /* Text / Dark Gray */
  color: #666666;
}

.identify {
  border: 1px solid red;
}
.form-contol-icon {
  border-left: none;
}

.search-bar-icon {
  padding: 13px 13px;
  background: none;
}

.search-bar-input {
  padding: 13px 2px;
  height: auto;
  z-index: 0 !important;
}

.txt-left {
  text-align: left !important;
}

.underlined {
  border: none;
  border-bottom: 1px solid #dddddd;
  border-radius: 0;
}
.underlined:focus {
  border-color: none;
  border-bottom-color: none;
  -webkit-box-shadow: none;
  box-shadow: none;

  border-bottom-color: #56d0e9;
  outline: 0;
}

.pv-2 {
  padding: 2rem 0 2rem 0;
}

.signInModal-Overlay {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 10001;
  background: rgba(51, 51, 51, 0.5);
}

.RSVP-event-item {
  border: 1px solid #E5E5E5;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
  word-break: keep-all;
  width: 600px;
  border-radius: 6px;
  margin-bottom: 24px;
  padding: 24px 0px 0px 24px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.16);
}

.RSVP-on-hover-background:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.RSVP-event-item-details {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
}
.RSVP-event-item-details-info {
  display: flex;
  flex-direction: column;
}
.RSVP-event-item-title {
  font-style: normal;
  font-family: muli;
  font-weight: bold;
  font-size: 24px;
  color: #222222;
  line-height: 30px;
  margin: 0;
}
.RSVP-event-item-seats {
  font-family: muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #9b59b6;
  margin: 0 !important;
}

.RSVP-event-item-description {
  font-family: muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: justify;
  line-height: 20px;
  word-break: break-word;

  /* Text / Dark Gray */
  color: #666666;
}
.RSVP-event-item-footer-dates {
  color: #000;
}
.RSVP-event-title-date {
  width: 600px;
  font-family: muli;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  text-align: left;
  line-height: 30px;
  margin-top: 47px;
  margin-bottom: 24px;
  color: #000000;
}

.RSVP-event-list-img {
  width: 124px;
  height: 124px;
  object-fit: cover;
  padding: 0;
  min-height: 124px;
  margin-right: 24px;
  border-radius: 8px;
}

.RSVP-event-item-noavatar {
  width: 100%;
  height: 170px;
  border-radius: 3px !important;
  background: rgba(155, 89, 182, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 15px;
}

.RSVP-event-item-noavatar-calendar {
  border-radius: 3px !important;
  height: 100px;
  padding: 0;
  max-width: 100px;
  background-color: white;
  border: 1px solid #312852;
  display: flex;
  flex-direction: column;
}

.RSVP-event-item-noavatar-calendar p {
  font-family: muli;
}

.RSVP-event-item-noavatar-calendar-header {
  background-color: #312852;
  height: 25px;
  min-height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.RSVP-event-item-noavatar-calendar-day {
  text-align: center;
  color: white;
  font-family: muli;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
}

.RSVP-event-item-noavatar-calendar-dayofmonth {
  text-align: center;
  color: black;
  margin: 0 !important;
  font-family: muli;
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 40px;
}

.RSVP-event-item-noavatar-calendar-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.RSVP-event-item-noavatar-calendar-month {
  text-align: center;
  color: black;
  font-size: 16px;
  margin: 0;
}

.RSVP-event-item-link {
  text-decoration: none;
}

.RSVP-event-item-button {
  font-family: muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  margin-top: 0px;
  background-color: transparent;
}

.RSVP-event-item-submit-button {
  font-family: muli;
  height: 37px;
  width: 100%;
  color: white;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

.RSVP-sign-up-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px 8px 16px;
  margin-left: -24px;
  border-top: 1px solid;
  border-color: #E5E5E5;
}
.RSVP-remaining-spots {
  font-family: muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 0px !important;
  color: #666666;
}
.RSVP-location-details {
  color: #666666;
  width: auto !important;
  text-align: center;
  border-radius: 3px;
  padding: 4px 8px 4px 8px;
  margin: 8px 0 8px 0 !important;
  font-size: 14px !important;
  line-height: 17px !important;
}
.RSVP-location-details:hover{
  background-color: #F0F1F2;
  cursor: pointer;
}

.RSVP-location-details-modal {
  width: 548px;
  height: auto;
  min-height: 220px;
  max-height: 492px;
  padding: 0px;
  background-color: white;
  border-radius: 8px;
}

.RSVP-location-details-modal:focus {
  outline: none;
}

.RSVP-location-details-modal-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #e3e3e3;
  width: 100%;
  height: 60px;
  padding: 18px 32px 18px 32px;
}

.RSVP-location-details-modal-title p {
  width: 100%;
  color:#222222;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  flex: 1;
  margin-bottom: 0px;
}

.RSVP-location-details-modal-content {
  min-height: 88px;
  max-height: 360px;
  padding-bottom: 24px;
  margin: 0px 16px 0px 16px;
  padding-top: 16px;
  overflow: hidden;
  overflow-y: scroll;
}
.RSVP-location-details-modal-content::-webkit-scrollbar {
  width: 10px;
  margin-right: 16px;
}

/* Handle */
.RSVP-location-details-modal-content::-webkit-scrollbar-thumb {
  background: #c4c4c4; 
  border-radius: 10px;
}

.RSVP-location-details-modal-content::-webkit-scrollbar-track {
  background: white; 
  margin: 24px 0px 24px 0px;
}

.RSVP-location-details-modal-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-top: 1px solid #e3e3e3;
  width: 100%;
  height: 72px;
  padding: 16px 24px 16px 24px;

}
.RSVP-event-item-button.sign-up {
  font-family: muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}
.RSVP-event-item-footer {
  display: flex;
  border-radius: 3px !important;
  padding: 0;
}

.RSVP-event-item-footer p {
  margin: 0;
  font-family: muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.RSVP-event-item-sign-btn-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-left: auto;
}

.RSVP-calendar {
  font-family: muli !important;
  border: 0 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 1rem;
}

.RSVP-calendar-event-indicator {
  width: 3px;
  height: 3px;
  border-radius: 3rem;
  margin: auto;
  background: #9b59b6;
}

.RSVP-calendar .react-calendar__month-view__days__day--weekend {
  color: black;
}

.RSVP-calendar .react-calendar__tile--active {
  background: #9b59b6 !important;
  border-radius: 1rem;
  color: white !important;
}
.RSVP-calendar .react-calendar__tile--active:enabled:focus {
  background: #9b59b6 !important;
  color: white !important;
}

.react-calendar__tile:enabled:hover {
  background-color: #e6e6e6;
  border-radius: 1rem;
}

.RSVP-calendar .react-calendar__tile {
  padding: 1rem;
}

.react-calendar__tile--now {
  background: none !important;
  color: #9b59b6 !important;
}

.RSVP-calendar .react-calendar__month-view__weekdays__weekday abbr {
  border-bottom: none !important;
  text-decoration: none !important;
  font-size: 12px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.25);
}
.RSVP-sign-in-custom-input-top-label.has-error {
  color: #E31212;
}
.form-control.has-error {
  border: 2px solid #E31212;
}
.form-control.has-error:focus {
  border: 2px solid #E31212 !important;
}

.RSVP-input:focus {
  box-shadow: none;
}

.RSVP-input {
  font-family: muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 24px;
  line-height: 20px;
  height: 56px;
  border-width: 2px;
  appearance: none;

  /* identical to box height */

  /* Black/Default */
  color: #000000;
}

.RSVP-input-dropdown {
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='rgb(167, 167, 167)' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 99%;
  background-position-y: 50%;
}
.RSVP-event-details-info {
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.16);

}
.RSVP-floating-button {
  padding: 0;
  border: transparent;
  background: transparent;
  color:white;
  margin-left: 12px;
}

.RSVP-floating-button-container {
  display: flex;
  align-items: center;
  position: fixed;
  border-radius: 100px;
  height: 40px;
  padding: 10px 16px 10px 16px;
  bottom: 24px;
  right: 20%;
  box-shadow: 0px 17px 10px -10px rgba(0,0,0,0.4);
  cursor: pointer;
}
.RSVP-floating-button-icon {
  color:white;
  font-weight: bolder;
  transform: rotate(-45deg);
}
.RSVP-back-icon {
  font-weight: bolder;
  margin-right: 12px;
  margin-left: -3px;
}

.RSVP-back-text {
  margin-top: 2px;
  font-family: muli;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.0375em;
}

.RSVP-event-item-button:hover {
  // background-color: rgba(0, 0, 0, 0.04);
  color: #9b59b6;
}

.react-calendar__month-view__weekdays__weekday {
  font-size: 12px;
  line-height: 15px;
  font-family: muli;
  font-style: normal;
  font-weight: normal;
}

.react-calendar__navigation__label__labelText {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575 !important;
}


.container-small {
  max-width: 680px !important;
}

@media only screen and (max-width: 1600px){
  .RSVP-floating-button-container {
    right: 15%;
  }
}
@media only screen and (max-width: 1400px){
  .RSVP-floating-button-container {
    right: 10%;
  }
}
@media only screen and (max-width: 1150px){
  .RSVP-floating-button-container {
    right: 5%;
  }
}

@media only screen and (max-width: 1150px){
  .RSVP-floating-button {
    display: none;
  }
  .RSVP-floating-button-container {
   padding: 10px;
   right: 2%;
  }
}


@media only screen and (max-width: 600px) {
  .error-topbar-container {
    margin: 0 2% 0 2%;
    flex-direction: column;
  }
  .topbar-right {
    font-size: 12px;
  }
  .error-body {
    width: 90%;
  }
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }
  .signInModal {
    height: 360px;
    inset: 20px;
  }
  .ml-5 {
    margin-left: 0;
  }
  .RSVP-event-item {
    width: 568px;
  }
  .RSVP-event-title-date{
    width: 568px;
  }
}







.el-event-list {
  background-color: #fafafa;
  padding:0px;
}

.el-container {
  display: flex;
  flex-direction: column;
  width:100%;
  margin: auto;
  margin: 73px 0px 0px 0px;
  min-height: 80vh;
}

.el-event-item-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  padding:5px;
}
.el-event-title-date {
  text-align: center;
  font-family: muli;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}

.el-on-hover-background:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.el-event-item {
  border: 1px solid #E5E5E5;
  background-color: white;
  display: flex;
  flex-direction: column;
  word-break: keep-all;
  width: 600px; // web
  border-radius: 6px;
  margin:0px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.16);
}

.el-event-list-img {
  width: 124px;
  height: 124px;
  object-fit: cover;
  padding: 0;
  border-radius: 12px;
}

.el-event-item-details {
  display: flex;
  flex-direction: row;
  width:100%;
  padding:10px;
}
.el-event-item-details-info {
  display: flex;
  flex-direction: column;
  padding-left:10px;
}

.el-event-item-title {
  width:100%;
  font-style: normal;
  font-family: muli;
  font-weight: bold;
  font-size: 24px;
  color: #222222;
  line-height: 30px;
}

.el-event-subtitle {
  font-family: muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #222222;
}
.el-sign-up-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid;
  border-color: #E5E5E5;
}
.el-remaining-spots {
  font-family: muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 0px !important;
  color: #666666;
  padding-left:10px;
}









.e-container {
  display: flex;
  flex-direction: column;
  width: 100%; 
  justify-content: flex-start;
  align-items: center;
  margin-top: 73px;
  z-index: 0;
  padding: 0px 16px 0px 16px;
}
.e-event-ended {
  font-family: muli;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}
.e-event {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.e-event-detail {
  text-align: center;
  width: 50%;
  padding: 10px;
}

.e-event-image {
  width: 100%;
  height: 200px;
  position: static;
  border-radius: 8px;
}

.e-event-detail-normal {
  min-height: 110px;
  background: white;
  border-radius: 8px;
  text-align: left;
  margin-top:10px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
}

.e-event-detail-info {
  background-color: white;
  border-radius: 8px;
  padding: 5px;
  padding-left: 15px;
}
.e-event-title {
  text-align: left;
  font-size: 20px;
  font-weight: 700;
  font-family: muli;
  font-style: normal;
  line-height: 25.1px;
  color: #222222;
}
.e-event-description {
  text-align: left;
  min-height: 110px;
  padding-left: 15px;
}
.e-event-description-web {
  text-align: left;
  min-height: 110px;
  padding-left: 15px;
}
.e-event-description-mobile {
  text-align: left;
  padding-top: 0px;
  padding-left: 10px;
  padding-bottom: 10px;
}
.e-event-sign-up {
  width: 50%;
  font-size: 12px;
}
.e-event-sign-up h3 {
  margin-top:0px;
  font-size: 24px;
  font-weight: bold;
}
.vanco-footer {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 14px;
  color: #a7afb3;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  a {
    color: #a7afb3;
  }
}

@media screen and (min-width: 900px) {
  .e-event-description-mobile {
    display: none;
  }
}
@media screen and (max-width: 600px) {

  .el-container {
    padding:5px;
  }
  .el-event-title-date {
    text-align: left;
    padding-left:10px;
  }

  .el-event-item {
    width: 100%;
  }
  .el-event-list-img {
    width: 100%;
    height: 100px;
    padding-bottom:5px;
  }
  .el-event-item-details {
    display: flex;
    flex-direction: column;
  }
  .el-event-item-details-info {
    width:100%;
    padding-left: 5px;
  }
  .el-event-subtitle {
    font-size: 14px;
    line-height: 18px;
  }




  .e-event {
    flex-direction: column;
  }
  .e-event-detail-overlay {
    top: -90px;
  }
  .e-event-detail {
    width: 100%;
  }
  .e-event-image {
    width: 100%;
    padding:10px;
    border-radius: 18px;
  }
  .e-event-description-web {
    display: none;
  }
  .e-event-sign-up{
    width: 100%;
  }
  .e-event-sign-up h3 {
    font-size: 16px;
  }
  .RSVP-location-details-modal {
    width: 90%;
    padding:0px;
    margin:0px;
  }
  .RSVP-sign-in {
    margin-top: 5px;
  }
}