$congregate-blue: #312752;

body {
  font-family: "Open Sans", sans-serif;
  color: #818897;
  font-size: 14px;
  font-weight: 400;
}

h1 {
  font-family: "Open Sans", sans-serif;
  color: #787f82;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin-top: 10px;
}

label {
  font-weight: inherit;
}

.form-group {
  margin-top: 24px;
  position: relative;
}

.hexea-gg {
  padding-bottom: 1px;
  padding-top: 1px;
  overflow-x: hidden;
  overflow-y: hidden;
}

.hexea-gg .form-group {
  margin-top: 8px;
}

.main-page {
  background-color: #ffffff;
  padding-left: 30px;
  padding-right: 30px;
}

.logo {
  font-family: "Lobster", cursive;
  font-size: 34px;
  color: $congregate-blue;
}

.logo-holder {
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
}

.content-holder {
  padding: 20px 30px 30px;
  background-color: #ffffff;
  margin: 0;
}

.footer {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  color: #a7afb3;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  z-index: 1;
  a {
    color: #a7afb3;
  }
}

.required-field-star {
  color: $congregate-blue;
  position: absolute;
  left: -10px;
  top: -6px;
}

.activity-spinner {
  color: $congregate-blue;
}

.form-control {
  font-size: 16px;
}
